
import { defineComponent, ref } from 'vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import AuthVerifyBlock from '@/components/pages/auth/AuthVerifyBlock.vue'
import AuthWhiteBlock from '@/components/pages/auth/AuthWhiteBlock.vue'

export default defineComponent({
  components: {
    TmColoredUl,
    TmAlert,
    AuthWhiteBlock,
    AuthVerifyBlock,
    PageAuth,
  },
  setup() {
    const showError = ref(false)
    const showSuccess = ref(false)

    const resendHandler = () => {
      showError.value = true

      setTimeout(() => {
        showSuccess.value = true
        showError.value = false
      }, 3000)
    }

    return {
      showError,
      showSuccess,
      resendHandler,
    }
  },
})
