
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
    TmColoredUl,
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
  emits: ['resend'],
  setup() {
    const showEditEmail = ref(false)
    const email = ref('')

    return {
      showEditEmail,
      email,
    }
  },
})
